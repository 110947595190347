
import React, {useState, useEffect, lazy, Suspense} from 'react';
import EditUnit from './components/edit-unit.component';
import Unit from './components/unit.component';
import EditWeapon from './components/edit-weapon.component';
import LatestLists from './components/latest-lists.component';
import EditUpgrade from './components/edit-upgrade.component';
import EditCommand from './components/edit-command.component';
import EditCondition from './components/edit-condition.component';
import EditDeployment from './components/edit-deployment.component';
import EditObjective from './components/edit-objective.component';
import EditImagePost from './components/edit-image-post.component';
import MissingImages from './components/missing-images.component';
import AdminUnitList from './components/admin-unit-list.component';
import CreateImagePost from './components/create-image-post.component';
import ImagePosts from './components/image-posts.component';
import ImagePost from './components/image-post.component';
import LatestUnits from './components/latest-units.component';
import ViewList from './components/view-list.component';
import PasswordReset from './components/password-reset.component';
import HomePage from './components/home-page.component';
import Login from './components/login.component';
import PublicProfile from './components/public-profile.component';
import Signup from './components/signup.component';
import Verify from './components/verify-email.component';
import UserLists from './components/user-lists.component';
import Collection from './components/collection.component';
import Notifications from './components/notifications.component';
import ForgotPassword from './components/forgot-password.component';
import PrivacyPolicy from './components/privacy.component';
import { GlobalContext } from "./GlobalContext";
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import axios from 'axios';
import './styles/comments.css';
import './styles/global.css';
import "./styles/imagePosts.css"
import EditUnitTranslations from './components/edit-unit-translations.component';
import EditCommandTranslations from './components/edit-command-translations.component';
import EditUnitListTranslations from './components/edit-unit-list-translations.component';
import EditCommandListTranslations from './components/edit-command-list-translations.component';
import EditUpgradeTranslations from './components/edit-upgrade-translations.component';
import EditUpgradeListTranslations from './components/edit-upgrade-list-translations.component';
import EditObjectiveTranslations from './components/edit-objective-translations.component';
import EditDeploymentTranslations from './components/edit-deployment-translations.component';
import EditConditionTranslations from './components/edit-condition-translations.component';
import EditBattlePlanListTranslations from './components/edit-battle-plan-list-translations.component';
import EditShatterpointUnitImages from './components/shatterpoint-list-builder/edit-unit-images.component';
import KeywordsPage from './components/keywords-page.component';
const SPListBuilder = lazy(() => import('./components/shatterpoint-list-builder/list-builder.component'));
const Database = lazy(() => import('./components/database.component'));
const CreateUnit = lazy(() => import('./components/create-unit.component'));
const CreateUnitNew = lazy(() => import('./components/create-unit-new.component'));
const CreateCounterpart = lazy(() => import('./components/create-counterpart.component'));
const CreateCommand = lazy(() => import('./components/create-command.component'));
const CreateUpgrade = lazy(() => import('./components/create-upgrade.component'));
const CreateFaction = lazy(() => import('./components/create-faction.component'));
const ListBuilder = lazy(() => import('./listbuilder/list-builder.component'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function App() {
  const [username, setUsername] = useState('')
  const [userId, setUserId] = useState('')
  const [ language, setLanguage ] = useState('en')
  const [ supporter, setSupporter ] = useState(false)
  const [ darkMode, setDarkMode ] = useState(localStorage.darkMode == 'true')
  const [ slimUpgradeSlots, setSlimUpgradeSlots ] = useState(true)
  const [ slimUnits, setSlimUnits ] = useState(false)
  const [ showUnitImages, setShowUnitImages ] = useState(false)
  const [ scrollColumns, setScrollColumns ] = useState(true)
  const [ ignoreCollection, setIgnoreCollection ] = useState(false)
  
  useEffect(() => {    
    axios.get('/api/user')
    .then(r => {
        setSupporter(r.data.supporter)
        if (r.data.supporter) {
          // get body tag and remove all ads
          document.getElementsByTagName('body')[0].className += 'supporter'
        }
        setLanguage(r.data.language ?? localStorage.language ?? 'en')
        if (r.data.username) {
          setSlimUpgradeSlots(r.data.slim_upgrade_slots)
          setSlimUnits(r.data.slim_units)
          setShowUnitImages(r.data.show_unit_images)
          setScrollColumns(r.data.scroll_columns)
          setIgnoreCollection(r.data.ignore_collection)
        }
    })
    /*
    const cookies = new Cookies();
    if (cookies.get('ttacookies')) {
      enableGoogle()
    }
    */
  }, [])

  /*
  const enableGoogle = () => {
    
    (window.adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=0
    window.enableGa()
  }
  */
  return (
    <GlobalContext.Provider value={{ignoreCollection, setIgnoreCollection, setUsername, username, language, setLanguage, setUserId, userId, supporter, setSupporter, darkMode, setDarkMode, slimUpgradeSlots, setSlimUpgradeSlots, slimUnits, setSlimUnits, showUnitImages, setShowUnitImages, scrollColumns, setScrollColumns}}>
    <div className={`App ${darkMode && 'dark'}`}>
    <QueryClientProvider client={queryClient}>
      <Router>
        
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
      <Route path="/editunits" component={AdminUnitList} />
      <Route path="/missingimages" component={MissingImages} />
      <Route path="/editunit/:id" component={EditUnit} />
      <Route path="/editupgrade/:id" component={EditUpgrade} />
      <Route path="/editcommand/:id" component={EditCommand} />
      <Route path="/editcondition/:id" component={EditCondition} />
      <Route path="/editdeployment/:id" component={EditDeployment} />
      <Route path="/editobjective/:id" component={EditObjective} />
      <Route path="/editimagepost/:id" component={EditImagePost} />
      <Route path="/editweapon/:id" component={EditWeapon} />
      <Route path="/legion/:factionParam/:listId?" component = {ListBuilder} />
      <Route path="/shatterpoint/listbuilder/:listId?" component = {SPListBuilder} />
      <Route path="/listbuilder/:factionParam/:listId?" component = {ListBuilder} />
      <Route path="/" exact component = {HomePage} />
      <Route path="/login" component={Login} />
      <Route path="/profile/lists" component={UserLists} />
      <Route path="/latest-lists" component={LatestLists} />
      <Route path="/viewprofile/:id" component={PublicProfile} />
      <Route path="/signup" component={Signup} />
      <Route path="/createunit/:unitId?" component={CreateUnit} />
      <Route path="/createunitnew/:unitId?" component={CreateUnitNew} />
      <Route path="/createcounterpart/:unitId?" component={CreateCounterpart} />
      <Route path="/createupgrade/:upgradeId?" component={CreateUpgrade} />
      <Route path="/database" component={Database} />
      <Route path="/createcommand/:commandId?" component={CreateCommand} />
      <Route path="/createfaction/:factionId?" component={CreateFaction} />
      <Route path="/createimagepost" component={CreateImagePost} />
      <Route path="/imageposts" component={ImagePosts} />
      <Route path="/unitposts" component={LatestUnits} />
      <Route path="/editunittranslations/:id" component={EditUnitTranslations} />
      <Route path="/editunittranslations" component={EditUnitListTranslations} />
      <Route path="/editcommandtranslations/:id" component={EditCommandTranslations} />
      <Route path="/editcommandtranslations" component={EditCommandListTranslations} />
      <Route path="/editupgradetranslations/:id" component={EditUpgradeTranslations} />
      <Route path="/editupgradetranslations" component={EditUpgradeListTranslations} />
      <Route path="/editbattleplantranslations" component={EditBattlePlanListTranslations} />
      <Route path="/editconditiontranslations/:id" component={EditConditionTranslations} />
      <Route path="/editobjectivetranslations/:id" component={EditObjectiveTranslations} />
      <Route path="/editdeploymenttranslations/:id" component={EditDeploymentTranslations} />
      <Route path="/shatterpoint/editunit/:id" component={EditShatterpointUnitImages} />
      <Route path="/notifications" component={Notifications} />
      <Route path="/imagepost/:id" component={ImagePost} />
      <Route path="/viewlist/:id" component={ViewList} />
      <Route path="/unit/:id" component={Unit} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/setpassword/:id/:code" component={PasswordReset} />
      <Route path="/collection" component={Collection} />
      <Route path="/privacy" component={PrivacyPolicy} />
      <Route path="/keywords" component={KeywordsPage} />
      <Route path="/verify/:id/:code" component = {Verify} />
      <Redirect to='/' />
      </Switch>
      </Suspense>
      </Router>
      </QueryClientProvider>

    </div>
    </GlobalContext.Provider>
  );
}

export default App;
